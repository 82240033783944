<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
    
          <div class="pagination">
            <div class="pagination__row">
              <p class="pagination__item" :class="{'pagination__item--active': day == 1}" v-on:click="day = 1">
                Wtorek 28 czerwca
              </p>
              <p class="pagination__item" :class="{'pagination__item--active': day == 2}" v-on:click="day = 2">
                Środa 29 czerwca
              </p>
              <p class="pagination__item" :class="{'pagination__item--active': day == 3}" v-on:click="day = 3">
                Czwartek 30 czerwca
              </p>
              <p class="pagination__item" :class="{'pagination__item--active': day == 4}" v-on:click="day = 4">
                Piątek 1 lipca
              </p>
              <p class="pagination__item" :class="{'pagination__item--active': day == 5}" v-on:click="day = 5">
                Sobota 2 lipca
              </p>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <p class="page-text__text" v-bind:key="1" v-if="day == 1">
              <strong>Wtorek 28 czerwca 2022 r. (patronka: Matka Boska Świętogórska)</strong><br />
              <strong>od godz. 13:00 </strong> - Rejestracja i czas na zakwaterowanie<br />
              <strong>godz. 17:30 </strong> - Kolacja<br />
              <strong>godz. 18:30 </strong> - Różaniec<br />
              <strong>godz. 19:00 </strong> - Eucharystia (bp Szymon Stułkowski)<br />
              <strong>godz. 21:00 </strong> - Paradisco (s. Katarzyna Katarzyniak oraz DJ Goatie)<br />
              <strong>godz. 22:00 </strong> - Nocny Targ<br />
              <strong>godz. 00:00 </strong> - Silentium w całym klasztorze (godz. 22.00 - silentium na polu namiotowym)
            </p>
            <p class="page-text__text" v-bind:key="2" v-if="day == 2">
              <strong>Środa 29 czerwca 2022 r. (patron: św. Jan Henryk Newman)</strong><br />
              <strong>godz. 8:30</strong> – Jutrznia<br />
              <strong>godz. 9:00</strong> – Śniadanie<br />
              <strong>godz. 10:00</strong> – Gra Świętych<br />
              <strong>godz. 14:00</strong> – Obiad<br />
              <strong>godz. 15:00</strong> – Koronka do Bożego Miłosierdzia + Godzina świadectw (Marek Teologicznie; bp Artur Ważny)<br />
              <strong>godz. 17:00</strong> – Koktajl + małe co nieco<br />
              <strong>godz. 18:30</strong> – Różaniec<br />
              <strong>godz. 19:00</strong> – Eucharystia (bp Artur Ważny)<br />
              <strong>godz. 20:30</strong> – Grill<br />
              <strong>godz. 21:30</strong> – Kino pod gwiazdami<br />
              <strong>godz. 00:00</strong> – Silentium w całym klasztorze (godz. 22.00 - silentium na polu namiotowym)
            </p>
            <p class="page-text__text" v-bind:key="3" v-if="day == 3">
              <strong>Czwartek 30 czerwca 2022 r. (patronka: bł. Chiara Luce Badano)</strong><br />
              <strong>godz. 8:30</strong> - Jutrznia<br />
              <strong>godz. 9:00</strong> - Śniadanie<br />
              <strong>godz. 10:00</strong> - Godzina świadectw (Jagoda Kwiecień; abp Grzegorz Ryś)<br />
              <strong>godz. 11:00</strong> - Owocowy poranek<br />
              <strong>godz. 11:30</strong> - Księga - spotkania w małych grupach i dzielenie<br />
              <strong>godz. 12:30</strong> - Obiad<br />
              <strong>godz. 13:30</strong> - Kuźnia talentów - warsztaty<br />
              <strong>godz. 16:00</strong> - Czas Nikodemów - czyli czas na rozmowę z księżmi i siostrami zakonnymi, którzy będą do naszej dyspozycji/czas dla wspólnot<br />
              <strong>godz. 17:30</strong> - Kolacja<br />
              <strong>godz. 18:30</strong> - Modlitwa indywidualna<br />
              <strong>godz. 19:00</strong> - Eucharystia (abp Grzegorz Ryś)<br />
              <strong>godz. 21:00</strong> - Koncert - Kana Band (support Dilerzy Miłości)<br />
              <strong>godz. 22:00</strong> - Nocny Targ<br />
              <strong>godz. 00:00</strong> - Silentium w całym klasztorze (godz. 22.00 - silentium na polu namiotowym)
            </p>
            <p class="page-text__text" v-bind:key="4" v-if="day == 4">
              <strong>Piątek 1 lipca 2022 r. (patron: św. Jose Sanchez del Rio)</strong><br />
              <strong>godz. 8:30</strong> - Jutrznia<br />
              <strong>godz. 9:00</strong> - Śniadanie<br />
              <strong>godz. 10:00</strong> - Godzina świadectw (Maja i Krzysztof Sowińscy; Weronika – Z Bogiem sprawa)<br />
              <strong>godz. 11:00</strong> - Owocowy poranek<br />
              <strong>godz. 11:30</strong> - Księga - spotkania w małych grupach i dzielenie<br />
              <strong>godz. 12:30</strong> - Obiad<br />
              <strong>godz. 13:30</strong> - Kuźnia talentów - warsztaty<br />
              <strong>godz. 16:00</strong> - Nabożeństwo Pokutne w formie adoracji krzyża i możliwość Spowiedzi Świętej (abp Adrian Galbas)<br />
              <strong>godz. 17:30</strong> - Kolacja<br />
              <strong>godz. 18:30</strong> - Modlitwa indywidualna<br />
              <strong>godz. 19:00</strong> - Eucharystia (abp Adrian Galbas)<br />
              <strong>godz. 21:00</strong> - Wielbienie (Chór i Orkiestra Jordan)<br />
              <strong>godz. 23:00</strong> - Nocny Targ<br />
              <strong>godz. 00:00</strong> - Silentium w całym klasztorze (godz. 22.00 - silentium na polu namiotowym)
            </p>
            <p class="page-text__text" v-bind:key="5" v-if="day == 5">
              <strong>Sobota 2 lipca 2022 r. (patron: św. Filip Neri)</strong><br />
              <strong>godz. 9:00</strong> – Eucharystia (ks. Marek Dudek COr - Superior Kongregacji Oratorium św. Filipa Neri w Gostyniu)<br />
              <strong>godz. 10:00</strong> – Śniadanie i zakończenie.
            </p>

          </transition>

          <br>
          <p class="page-text__text">Nie czekaj, zarejestruj się!</p>
          <p class="page-text__text">Do zobaczenia!</p>
          <div class="page-text__btn page-text__btn--left" v-if="showEvent">
            <router-link to="/rejestracja" class="page-text__btn page-text__btn--left">
              <Btn text="Zarejestruj się" />
            </router-link>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
  export default {
    data() {
      return {
        showEvent: false,
        day: 1,
      };
    },
    async mounted() {
      this.showEvent = await this.$func.compareDate();
    },
  };
</script>
<style scoped lang="scss">
  .tshirts {
    width: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
